export const fetchAll = 'fetchAll'
export const fetchById = 'fetchById'
export const create = 'create'
export const updateList = 'updateList'
export const updateById = 'updateById'
export const restoreById = 'restoreById'
export const deleteList = 'deleteList'
export const deleteById = 'deleteById'
export const clearList = 'clearList'
export const clearEntity = 'clearEntity'
export const clearAll = 'clearAll'

export const pending = 'Pending'
export const resolved = 'Resolved'
export const rejected = 'Rejected'

export const loading = 'Loading'
export const error = 'Error'

export const reason = 'Reason'