import { ActionMethods } from "../object/ActionMethods"
import {
    create,
    deleteList,
    deleteById,
    fetchAll,
    fetchById,
    clearList,
    clearEntity,
    clearAll,
    pending,
    rejected,
    resolved,
    updateList,
    updateById,
    restoreById
} from "./Constants"

export const createCustomActionMethods = function (actions) {
    return new ActionMethods(
        {
            pending: actions[create + pending],
            success: actions[create + resolved],
            failure: actions[create + rejected]
        },
        {
            pending: actions[updateList + pending],
            success: actions[updateList + resolved],
            failure: actions[updateList + rejected]
        },
        {
            pending: actions[updateById + pending],
            success: actions[updateById + resolved],
            failure: actions[updateById + rejected]
        },
        {
            pending: actions[fetchAll + pending],
            success: actions[fetchAll + resolved],
            failure: actions[fetchAll + rejected]
        },
        {
            pending: actions[fetchById + pending],
            success: actions[fetchById + resolved],
            failure: actions[fetchById + rejected]
        },
        {
            pending: actions[deleteList + pending],
            success: actions[deleteList + resolved],
            failure: actions[deleteList + rejected]
        },
        {
            pending: actions[deleteById + pending],
            success: actions[deleteById + resolved],
            failure: actions[deleteById + rejected]
        },
        {
            pending: actions[restoreById + pending],
            success: actions[restoreById + resolved],
            failure: actions[restoreById + rejected]
        },
        {
            success: actions[clearList + resolved],
        },
        {
            success: actions[clearEntity + resolved],
        },
        {
            success: actions[clearAll + resolved],
        },
    )
}
