import { createSlice } from "@reduxjs/toolkit"
import { capitalize } from "../util/stringHelper"
import {
    fetchAll,
    fetchById,
    loading,
    error,
    create,
    updateList,
    updateById,
    deleteList,
    deleteById,
    restoreById,
    clearList,
    clearEntity,
    clearAll,
    pending,
    rejected,
    resolved,
    reason
} from "./Constants"

export const createCustomSlice = function (resourceName) {

    const resList = resourceName + 'List'
    const resName = resourceName

    return createSlice({
        name: resourceName,
        initialState: {
            [resList]: [],
            [resName]: null,

            [resName + capitalize(fetchAll) + loading]: false,
            [resName + capitalize(fetchAll) + error]: null,

            [resName + capitalize(fetchById) + loading]: false,
            [resName + capitalize(fetchById) + error]: null,

            [resName + capitalize(create) + reason]: null,
            [resName + capitalize(create) + loading]: false,
            [resName + capitalize(create) + error]: null,

            [resName + capitalize(updateList) + reason]: null,
            [resName + capitalize(updateList) + loading]: false,
            [resName + capitalize(updateList) + error]: null,

            [resName + capitalize(updateById) + reason]: null,
            [resName + capitalize(updateById) + loading]: false,
            [resName + capitalize(updateById) + error]: null,

            [resName + capitalize(deleteList) + reason]: null,
            [resName + capitalize(deleteList) + loading]: false,
            [resName + capitalize(deleteList) + error]: null,

            [resName + capitalize(deleteById) + reason]: null,
            [resName + capitalize(deleteById) + loading]: false,
            [resName + capitalize(deleteById) + error]: null,

            [resName + capitalize(restoreById) + reason]: null,
            [resName + capitalize(restoreById) + loading]: false,
            [resName + capitalize(restoreById) + error]: null,

            [resName + capitalize(clearList) + loading]: false,
            [resName + capitalize(clearList) + error]: null,

            [resName + capitalize(clearEntity) + loading]: false,
            [resName + capitalize(clearEntity) + error]: null,
        },
        reducers: {
            // fetchAll
            [fetchAll + pending]: (state, action) => {
                state[resName + capitalize(fetchAll) + loading] = true
            },
            [fetchAll + resolved]: (state, action) => {
                let payload = action.payload

                if (payload?.append && Array.isArray(state[resList]?.data)) {
                    payload.data = state[resList].data.concat(payload.data)
                }

                state[resList] = payload
                state[resName + capitalize(fetchAll) + loading] = false
                state[resName + capitalize(fetchAll) + error] = null
            },
            [fetchAll + rejected]: (state, action) => {
                state[resList] = null
                state[resName + capitalize(fetchAll) + error] = action.payload
                state[resName + capitalize(fetchAll) + loading] = false
            },

            // fetchById
            [fetchById + pending]: (state, action) => {
                state[resName + capitalize(fetchById) + loading] = true
            },
            [fetchById + resolved]: (state, action) => {
                state[resName] = action.payload
                state[resName + capitalize(fetchById) + loading] = false
                state[resName + capitalize(fetchById) + error] = null
            },
            [fetchById + rejected]: (state, action) => {
                state[resName] = null
                state[resName + capitalize(fetchById) + error] = action.payload
                state[resName + capitalize(fetchById) + loading] = false
            },

            // create
            [create + pending]: (state, action) => {
                state[resName + capitalize(create) + reason] = action.payload
                state[resName + capitalize(create) + loading] = true
            },
            [create + resolved]: (state, action) => {
                if (action.payload) {
                    state[resName] = action.payload
                }

                state[resName + capitalize(create) + loading] = false
                state[resName + capitalize(create) + error] = null
            },
            [create + rejected]: (state, action) => {
                state[resName] = null
                state[resName + capitalize(create) + error] = action.payload
                state[resName + capitalize(create) + loading] = false
            },

            // updateList
            [updateList + pending]: (state, action) => {
                state[resName + capitalize(updateList) + reason] = action.payload
                state[resName + capitalize(updateList) + loading] = true
            },
            [updateList + resolved]: (state, action) => {
                // state[resList] = action.payload
                state[resName + capitalize(updateList) + loading] = false
                state[resName + capitalize(updateList) + error] = null
            },
            [updateList + rejected]: (state, action) => {
                state[resList] = null
                state[resName + capitalize(updateList) + error] = action.payload
                state[resName + capitalize(updateList) + loading] = false
            },

            // updateById
            [updateById + pending]: (state, action) => {
                state[resName + capitalize(updateById) + reason] = action.payload
                state[resName + capitalize(updateById) + loading] = true
            },
            [updateById + resolved]: (state, action) => {
                if (action.payload) {
                    state[resName] = action.payload
                }
                state[resName + capitalize(updateById) + loading] = false
                state[resName + capitalize(updateById) + error] = null
            },
            [updateById + rejected]: (state, action) => {
                //state[resName] = null
                state[resName + capitalize(updateById) + error] = action.payload
                state[resName + capitalize(updateById) + loading] = false
            },

            // deleteList
            [deleteList + pending]: (state, action) => {
                state[resName + capitalize(deleteList) + reason] = action.payload
                state[resName + capitalize(deleteList) + loading] = true
            },
            [deleteList + resolved]: (state, action) => {
                state[resName + capitalize(deleteList) + loading] = false
                state[resName + capitalize(deleteList) + error] = null
            },
            [deleteList + rejected]: (state, action) => {
                state[resName + capitalize(deleteList) + error] = action.payload
                state[resName + capitalize(deleteList) + loading] = false
            },

            // deleteById
            [deleteById + pending]: (state, action) => {
                state[resName + capitalize(deleteById) + reason] = action.payload
                state[resName + capitalize(deleteById) + loading] = true
            },
            [deleteById + resolved]: (state, action) => {
                state[resName + capitalize(deleteById) + loading] = false
                state[resName + capitalize(deleteById) + error] = null
            },
            [deleteById + rejected]: (state, action) => {
                state[resName + capitalize(deleteById) + error] = action.payload
                state[resName + capitalize(deleteById) + loading] = false
            },

            // restoreById
            [restoreById + pending]: (state, action) => {
                state[resName + capitalize(restoreById) + reason] = action.payload
                state[resName + capitalize(restoreById) + loading] = true
            },
            [restoreById + resolved]: (state, action) => {
                if (action.payload) {
                    state[resName] = action.payload
                }
                state[resName + capitalize(restoreById) + loading] = false
                state[resName + capitalize(restoreById) + error] = null
            },
            [restoreById + rejected]: (state, action) => {
                state[resName + capitalize(restoreById) + error] = action.payload
                state[resName + capitalize(restoreById) + loading] = false
            },

            // clear list
            [clearList + resolved]: (state, action) => {
                state[resList] = []
                state[resName + capitalize(fetchAll) + error] = null
                state[resName + capitalize(updateList) + error] = null
                state[resName + capitalize(updateList) + reason] = null
                state[resName + capitalize(deleteList) + error] = null
                state[resName + capitalize(deleteList) + reason] = null
            },

            // clear entity
            [clearEntity + resolved]: (state, action) => {
                state[resName] = null
                state[resName + capitalize(fetchById) + error] = null
                state[resName + capitalize(create) + error] = null
                state[resName + capitalize(updateById) + error] = null
                state[resName + capitalize(deleteById) + error] = null
                state[resName + capitalize(restoreById) + error] = null
            },

            // clear all
            [clearAll + resolved]: (state, action) => {
                state[resList] = []
                state[resName] = null
                state[resName + capitalize(fetchAll) + error] = null
                state[resName + capitalize(fetchById) + error] = null
                state[resName + capitalize(create) + error] = null
                state[resName + capitalize(updateList) + error] = null
                state[resName + capitalize(updateById) + error] = null
                state[resName + capitalize(deleteList) + error] = null
                state[resName + capitalize(deleteById) + error] = null
                state[resName + capitalize(restoreById) + error] = null
            },
        },
    })
}
