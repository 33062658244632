import axios from 'axios'

const request = (url, configOptions) => {

    let config = {
        url: url,
        ...configOptions,
    }

    let response
    try {
        response = axios(config)
    } catch (error) {
        console.error(error)
    }
    return response
}

export default request
