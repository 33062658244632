export class ServiceMethods {
    constructor(create, updateList, updateById, findAll, findById, deleteList, deleteById, restoreById, clearList, clearEntity, clearAll) {
        this.create = create
        this.updateList = updateList
        this.updateById = updateById
        this.findAll = findAll
        this.findById = findById
        this.deleteList = deleteList
        this.deleteById = deleteById
        this.restoreById = restoreById
        this.clearList = clearList
        this.clearEntity = clearEntity
        this.clearAll = clearAll
    }
}
